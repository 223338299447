import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Load icons from the "Solid" style.
 */
import { faAngleDown as faAngleDownSolid } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleUp as faAngleUpSolid } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faBackwardStep as faBackwardStepSolid } from '@fortawesome/pro-solid-svg-icons/faBackwardStep';
import { faCirclePlay as faCirclePlaySolid } from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import { faCirclePause as faCirclePauseSolid } from '@fortawesome/pro-solid-svg-icons/faCirclePause';
import { faForwardStep as faForwardStepSolid } from '@fortawesome/pro-solid-svg-icons/faForwardStep';
import { faList as faListSolid } from '@fortawesome/pro-solid-svg-icons/faList';
import { faPlay as faPlaySolid } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPause as faPauseSolid } from '@fortawesome/pro-solid-svg-icons/faPause';
import { faRotateLeft as faRotateLeftSolid } from '@fortawesome/pro-solid-svg-icons/faRotateLeft';
import { faRotateRight as faRotateRightSolid } from '@fortawesome/pro-solid-svg-icons/faRotateRight';
import { faSpinnerThird as faSpinnerThirdSolid } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';

library.add(
  faAngleDownSolid,
  faAngleUpSolid,
  faBackwardStepSolid,
  faCirclePlaySolid,
  faCirclePauseSolid,
  faForwardStepSolid,
  faListSolid,
  faPlaySolid,
  faPauseSolid,
  faRotateLeftSolid,
  faRotateRightSolid,
  faSpinnerThirdSolid,
);

/**
 * Load icons from the "Duotone" style.
 */
import { faSignalStream as faSignalStreamDuo } from '@fortawesome/pro-duotone-svg-icons/faSignalStream';
import { faSignalStreamSlash as faSignalStreamSlashDuo } from '@fortawesome/pro-duotone-svg-icons/faSignalStreamSlash';

library.add(
  faSignalStreamDuo,
  faSignalStreamSlashDuo,
);
