import { shallowReactive, type Component } from 'vue';

export default class Modals {
  /**
   * Active modals.
   */
  protected modals: Map<symbol, Modal>;

  /**
   * Modal constructor.
   */
  constructor() {
    this.modals = shallowReactive<Map<symbol, Modal>>(new Map);
  }

  /**
   * Show modal.
   *
   * @param { Modal } modal
   * @returns { void }
   */
  public show = (modal: Modal): void => {
    this.modals.set(Symbol.for('modal'), modal);
  };

  /**
   * Hide modal.
   *
   * @param { symbol } id
   * @returns { void }
   */
  public hide = (id: symbol): void => {
    this.modals.delete(id);
  };

  /**
   * Get all active modals.
   */
  get all(): Map<symbol, Modal> {
    return this.modals;
  }
}

export type Modal = {
  component: Component;
  props?: { [key: string]: unknown };
};
