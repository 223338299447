<template>
  <svg
    class="h-full w-full"
    viewBox="0 0 100 100"
  >
    <circle
      stroke-dasharray="251.2"
      :stroke-dashoffset="offset"
      stroke-width="11"
      stroke-linecap="round"
      class="origin-center -rotate-90 fill-transparent stroke-current text-current transition-[stroke-dashoffset] duration-[0.35]"
      cx="50"
      cy="50"
      r="40"
    />
    <circle
      class="fill-transparent stroke-current text-current opacity-30"
      stroke-width="11"
      cx="50"
      cy="50"
      r="40"
    />
    <text
      x="50"
      y="50"
      font-family="Inter"
      text-anchor="middle"
      alignment-baseline="middle"
      class="fill-current text-14 font-bold"
    >{{ percentage }}%</text>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  percentage: {
    type: Number,
    default: 0,
  },
});

const offset = computed((): number => (251.2 - (251.2 * props.percentage)) / 100);
</script>
