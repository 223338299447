import { computed, ref, type ComputedRef, type Ref } from 'vue';
import videojs from 'video.js';
import type Player from 'video.js/dist/types/player';
import type { LiveShow } from 'types/models';
import { EventEmitter } from 'events';

export default class LivePlayer {
  /**
   * Active live show.
   *
   * @var { Ref<LiveShow | undefined> }
   */
  protected liveShow: Ref<LiveShow | undefined> = ref<LiveShow>();

  /**
   * Current player instance.
   *
   * @returns { Ref<Player | undefined> }
   */
  protected player: Ref<Player | undefined> = ref<Player>();

  /**
   * Whether player is playing or not.
   *
   * @returns { Ref<boolean> }
   */
  protected isPlaying: Ref<boolean> = ref<boolean>(false);

  /**
   * Whether player is ready or not.
   *
   * @returns { Ref<boolean> }
   */
  protected isOpen: Ref<boolean> = ref<boolean>(false);

  /**
   * Whether player is waiting or not.
   *
   * @returns { Ref<boolean> }
   */
  protected isWaiting: Ref<boolean> = ref<boolean>(true);

  /**
   * Whether player is maximized or not.
   *
   * @returns { Ref<boolean> }
   */
  protected isMaximized: Ref<boolean> = ref<boolean>(false);

  /**
   * Load live show.
   *
   * @param { LiveShow } liveShow
   * @returns { this }
   */
  public load = (liveShow: LiveShow): this => {
    this.liveShow.value = liveShow;
    return this;
  };

  /**
   * Create live player.
   *
   * @param { HTMLAudioElement } element
   * @returns { Player }
   */
  public createPlayer = (element: HTMLAudioElement): this => {
    // If we already have a player,
    // then we need to dispose of that first.
    if (this.player.value !== undefined) {
      this.player.value.dispose();
    }

    // Create new player.
    this.player.value = videojs(element, {
      tech: ['html5'],
      html5: {
        nativeAudioTracks: videojs.browser.IS_SAFARI,
        nativeVideoTracks: videojs.browser.IS_SAFARI,
        vhs: {
          overrideNative: !videojs.browser.IS_SAFARI,
          withCredentials: false,
          cacheEncryptionKeys: true,
          useNetworkInformationApi: true,
          experimentalBufferBasedABR: true,
          enableLowInitialPlaylist: true,
        },
      },
      liveTracker: {
        trackingThreshold: 0,
        liveTolerance: 15,
      },
      audioOnlyMode: true,
      autoplay: false,
      controls: false,
      fluid: false,
      loop: false,
      muted: false,
      liveui: true,
      preload: 'auto',
      language: 'da',
      children: [
        'mediaLoader',
        'liveTracker',
      ],
      sources: [
        {
          src: this.liveShow.value?.stream_url,
          type: 'application/x-mpegURL',
        },
      ],
    });

    // Handle listeners.
    this.player.value?.on('loadstart', () => (this.isWaiting.value = true));
    this.player.value?.on('ready', () => this.openPlayer());
    this.player.value?.on('canplaythrough', () => (this.isWaiting.value = false));
    this.player.value?.on('waiting', () => (this.isWaiting.value = true));
    this.player.value?.on('pause', () => (this.isPlaying.value = false));
    this.player.value?.on('playing', () => (this.isPlaying.value = true));
    this.player.value?.on('dispose', () => this.resetAndClosePlayer());
    this.player.value?.on('error', (error: ErrorEvent) => this.handleError(error));
    this.player.value?.on('xhr-hooks-ready', () => this.player.value?.ready(() => {
      // @ts-expect-error vhs might not exist on Tech.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.player.value?.tech().vhs.xhr.onResponse((_request: object, error: ErrorEvent, response: { statusCode: number }) => {
        if (response.statusCode < 400 || response.statusCode > 599) {
          return;
        }

        // If we're receiving a client/server error.
        // We assume we've lost the live signal.
        this.handleLoS();
      });
    }));

    return this;
  };

  /**
   * Play live signal.
   *
   * @returns { Promise<LivePlayer> }
   */
  public play = async (): Promise<LivePlayer> => {
    try {
      await this.player.value?.play();
    } catch (videoError) { /* Supress console errors. */ }
    return this;
  };

  /**
   * Pause live signal.
   *
   * @returns { LivePlayer }
   */
  public pause = (): LivePlayer => {
    this.player.value?.pause();
    return this;
  };

  /**
   * Toogle play/pause of player.
   *
   * @returns { Promise<void> }
   */
  public togglePlayPause = async (): Promise<void> => {
    this.isPlaying.value ? this.pause() : await this.goToLive().play();
  };

  /**
   * Whether live player is currently behind the live signal.
   *
   * @returns { boolean }
   */
  public isBehindLive = (): boolean => {
    if (!this.player.value) {
      throw new Error('Could not check position of live signal, since there is no player.');
    }

    // @ts-expect-error videojs live tracker is not loaded.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.player.value?.liveTracker?.behindLiveEdge() as boolean;
  };

  /**
   * Move live player position to edge of live signal.
   *
   * @returns { this }
   */
  public goToLive = (): this => {
    if (!this.player.value) {
      throw new Error('Could not move player to live signal, since there is no player.');
    }

    if (this.isBehindLive()) {
      // @ts-expect-error videojs live tracker is not loaded.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.player.value?.liveTracker?.seekToLiveEdge();
    }

    return this;
  };

  protected handleLoS = (): this => {
    console.log('[Live] Live signalet blev lukket.');
    this.player.value?.dispose();
    return this;
  };

  protected handleError = (_event: ErrorEvent) => {
    // Get player error.
    const playerError = this.player.value?.error();

    // Handle different errors.
    switch (playerError?.code) {
      case 4:
        console.error('[Live] Intet live signal.');
        break;
      case 3:
        console.error('[Live] Decoding fejl.');
        break;
      case 2:
        console.error('[Live] Netværks fejl.');
        break;
      case 1:
        console.error('[Live] Afbrudt.');
        break;
      default:
        console.error('[Live] Der opstod en ukendt fejl.');
    }

    // Dispose of player.
    this.player.value?.dispose();
  };

  /**
   * Reset player state.
   *
   * @returns { this }
   */
  public resetPlayer = (): this => {
    this.player.value = undefined;
    this.liveShow.value = undefined;
    this.isPlaying.value = false;
    this.isWaiting.value = true;
    this.isMaximized.value = false;
    return this;
  };

  /**
   * Reset and close player.
   *
   * @returns { this }
   */
  public resetAndClosePlayer = (): this => {
    this.player.value?.dispose();
    this.resetPlayer();
    this.closePlayer();
    return this;
  };

  /**
   * Open player.
   *
   * @returns { this }
   */
  public openPlayer = (): this => {
    this.isOpen.value = true;
    return this;
  };

  /**
   * Close player.
   *
   * @returns { this }
   */
  public closePlayer = (): this => {
    this.isOpen.value = false;
    return this;
  };

  /**
   * Maximize player.
   *
   * @returns { this }
   */
  public maximize = (): this => {
    this.isMaximized.value = true;
    return this;
  };

    /**
   * Minimize player.
   *
   * @returns { this }
   */
  public minimize = (): this => {
    this.isMaximized.value = false;
    return this;
  };

  /**
   * Get the current live show.
   *
   * @returns { ComputedRef<LiveShow | undefined> }
   */
  get current(): ComputedRef<LiveShow | undefined> {
    return computed<LiveShow | undefined>((): LiveShow | undefined => this.liveShow.value);
  }

  /**
   * Get whether player is open or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get open(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isOpen.value);
  }

  /**
   * Get whether player is maximized or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get maximized(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isMaximized.value);
  }

  /**
   * Get whether player is waiting or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get waiting(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isWaiting.value);
  }

  /**
   * Get whether player is currently playing or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get playing(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isPlaying.value);
  }
}
