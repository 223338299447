<template>
  <TransitionRoot
    as="template"
    :show="show"
  >
    <Dialog
      as="div"
      class="fixed inset-0 z-40"
      @close="closeModal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-400/10 backdrop-blur-lg" aria-hidden="true" />
      </TransitionChild>
      <div class="fixed inset-0 z-10">
        <div class="mx-10 flex h-screen items-center justify-center p-4 sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative flex max-h-[90%] max-w-[90%] flex-col overflow-hidden align-bottom transition-all sm:min-w-[20%]">
              <slot name="modal" :close="closeModal">
                <div v-bind="$attrs" class="relative mx-10 mb-15 flex h-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-lg sm:align-middle">
                  <div class="flex h-full flex-1 flex-col overflow-hidden sm:flex sm:items-start">
                    <div class="mt-15 flex h-full w-full flex-1 flex-col overflow-hidden">
                      <div class="flex shrink-0 items-center px-15" :class="[slots.title ? 'justify-between border-b pb-10' : 'justify-end']">
                        <DialogTitle
                          v-if="slots.title"
                          as="h3"
                          class="text-18 font-medium leading-20 text-gray-900"
                        >
                          <slot name="title" :close="closeModal" />
                        </DialogTitle>
                        <button type="button" aria-label="Luk pop up" class="group rounded-md bg-white text-20 text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 group-hover:text-indigo-600" @click="closeModal">
                          <span class="sr-only">Luk</span>
                          <FontAwesomeIcon
                            :icon="['fas', 'xmark']"
                            class="group-hover:text-indigo-600"
                            fixed-width
                          />
                        </button>
                      </div>
                      <div class="flex h-full max-h-full grow flex-col overflow-y-auto px-15" :class="[slots.title ? 'pt-15' : '-mt-8', { 'pb-15': slots.footer }]">
                        <slot :close="closeModal" />
                      </div>
                    </div>
                  </div>
                  <div v-if="slots.footer" class="flex-initial">
                    <slot name="footer" :close="closeModal" />
                  </div>
                  <div v-if="loading" class="z-5 absolute inset-0 flex items-center justify-center rounded-xl p-16 backdrop-blur sm:px-24">
                    <div v-if="loadingPercentage !== undefined" class="h-100 w-100">
                      <RadialProgressBar
                        class="text-indigo-600"
                        :percentage=loadingPercentage
                      />
                    </div>
                    <FontAwesomeIcon
                      v-else
                      :icon="['fad', 'spinner-third']"
                      class="text-90 text-indigo-600"
                      fixed-width
                      spin
                    />
                  </div>

                </div>
              </slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { useSlots, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';

import RadialProgressBar from '@shared/components/ui/progress/Radial.vue';

/**
 * ------------------------------------------------------------
 * Component properties.
 * ------------------------------------------------------------
 */
const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingPercentage: {
    type: Number,
  },
});

/**
 * ------------------------------------------------------------
 * Emits
 * ------------------------------------------------------------
 */
const emit = defineEmits(['close', 'open']);

/**
 * ------------------------------------------------------------
 * Component options
 * ------------------------------------------------------------
 */
defineOptions({
  inheritAttrs: false,
});

/**
 * ------------------------------------------------------------
 * Composables.
 * ------------------------------------------------------------
 */
const slots = useSlots();

/**
 * ------------------------------------------------------------
 * Methods
 * ------------------------------------------------------------
 */

/**
 * Close modal.
 *
 * @returns void
 */
const closeModal = (): void => {
  emit('close');
};

/**
 * ------------------------------------------------------------
 * Watchers
 * ------------------------------------------------------------
 */

/**
 * Watch for visibility changes.
 *
 * @returns void
 */
watch(() => props.show, (value: boolean) => {
  if (!value) {
    return;
  }

  emit('open');
});
</script>
