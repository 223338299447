import { EventEmitter } from 'events';
import { computed, ref, type ComputedRef, type Ref } from 'vue';
import type { PlayerMedia } from 'types/player';

export default class PodcastPlayer {
  /**
   * Events emitter.
   *
   * @var { EventEmitter }
   */
  private events: EventEmitter = new EventEmitter;

  /**
   * Player options.
   *
   * @var { PodcastPlayerOptions }
   */
  protected options: PodcastPlayerOptions;

  /**
   * Player element.
   *
   * @var { Ref<HTMLAudioElement | undefined> }
   */
  protected player: Ref<HTMLAudioElement | undefined> = ref<HTMLAudioElement>();

  /**
   * Whether player is open or not.
   *
   * @var { Ref<boolean> }
  */
  protected isOpen: Ref<boolean> = ref<boolean>(false);

  /**
   * Currently loaded media in player.
   *
   * @var { Ref<PlayerMedia | undefined> }
   */
  protected loadedMedia: Ref<PlayerMedia | undefined> = ref<PlayerMedia>();

  /**
   * Playable state of media.
   *
   * @var { Ref<PodcastPlayerMediaState> }
  */
  protected isPlayable: Ref<PodcastPlayerMediaState> = ref<PodcastPlayerMediaState>(PodcastPlayerMediaState.NOT_PLAYABLE);

  /**
   * Whether player is currently playing or not.
   *
   * @var { Ref<boolean> }
  */
  protected isPlaying: Ref<boolean> = ref<boolean>(false);

  /**
   * Whether player is maximized or not.
   *
   * @var { Ref<boolean> }
  */
  protected isMaximized: Ref<boolean> = ref<boolean>(false);

  /**
   * Total playing time of media.
   *
   * @var { Ref<number> }
  */
  protected totalPlayingTime: Ref<number> = ref<number>(0);

  /**
   * Elapsed playing time of media.
   *
   * @var { Ref<number> }
  */
  protected elapsedPlayingTime: Ref<number> = ref<number>(0);

  /**
   * PodcastPlayer constructor.
   */
  constructor(options: PodcastPlayerOptions) {
    this.options = options;
  }

  /**
   * Create player instance.
   *
   * @returns { this }
   */
  public createPlayer = (): this => {
    // Set player instance.
    this.player.value = new Audio;

    // Configure player's event listeners.
    this.player.value.addEventListener('loadstart', () => (this.isPlayable.value = PodcastPlayerMediaState.NOT_PLAYABLE));
    this.player.value.addEventListener('canplay', () => (this.isPlayable.value = PodcastPlayerMediaState.PLAYABLE));
    this.player.value.addEventListener('canplaythrough', () => (this.isPlayable.value = PodcastPlayerMediaState.FINISHABLE));
    this.player.value.addEventListener('loadedmetadata', this.handleMetadata);
    this.player.value.addEventListener('pause', this.handlePausing);
    this.player.value.addEventListener('playing', this.handlePlaying);
    this.player.value.addEventListener('ended', this.handleEnded);
    this.player.value.addEventListener('timeupdate', this.updateProgress);

    // Configure player's error listener.
    this.player.value.addEventListener('error', (event: ErrorEvent) => this.events.emit('error', this, event));

    return this;
  };

  /**
   * Register event listener for player.
   *
   * @param { PodcastPlayerEvent } event
   * @param { Function } listener
   * @returns { void }
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public on = (event: PodcastPlayerEvent, listener: (...args: any[]) => void): void => {
    this.events.on(event, listener);
  };

  /**
   * Remove event listener for player.
   *
   * @param { PodcastPlayerEvent } event
   * @param { Function } listener
   * @returns { void }
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public off = (event: PodcastPlayerEvent, listener: (...args: any[]) => void): void => {
    this.events.off(event, listener);
  };

  /**
   * Load media into player.
   *
   * @param { PlayerMedia } media
   * @returns { PodcastPlayer | undefined }
   */
  public load = (media: PlayerMedia): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return this.createPlayer().load(media);
    }

    // If available, set the internal media object
    // with metadata of currently loaded media.
    this.loadedMedia.value = media;

    // Set media URL of player.
    this.player.value.src = media.media.url;

    // Emit event.
    this.events.emit('load', this, media);

    // Load new media resource into player.
    this.player.value.load();

    return this;
  };

  /**
   * Load from cache.
   *
   * @param { PodcastPlayerState } value
   * @returns { this }
   */
  public loadFromCache = (value: PodcastPlayerState): this => {
    if (!this.player.value) {
      return this.createPlayer().loadFromCache(value);
    }

    // If available, set the internal media object
    // with metadata of currently loaded media.
    this.loadedMedia.value = value.data;

    // Set media URL of player.
    this.player.value.src = value.data.media.url;

    // Set starting point of media.
    this.player.value.currentTime = value.timeElapsed;

    // Emit event.
    this.events.emit('load', this, value.data);
    this.events.emit('cached', this, value.data);

    // Load new media resource into player.
    this.player.value.load();

    return this;
  };

  /**
   * Toggle play/pause media of player.
   *
   * @returns { Promise<PodcastPlayer> }
   */
  public togglePlayPause = async (): Promise<PodcastPlayer> => {
    this.isPlaying.value ? this.pause() : await this.play();
    return this;
  };

  /**
   * Play loaded media in player.
   *
   * @returns { Promise<PodcastPlayer> }
   */
  public play = async (): Promise<PodcastPlayer> => {
    if (!this.isPlayable) {
      throw new Error('Player is not playable. Make sure a media is loaded and is playable.');
    }

    // Play media.
    await this.player.value?.play();

    return this;
  };

  /**
   * Pause loaded media in player.
   *
   * @returns { PodcastPlayer }
   */
  public pause = (): PodcastPlayer => {
    // Pause media.
    this.player.value?.pause();

    return this;
  };

  /**
   * Handle metadata of loaded media.
   *
   * @returns { void }
   */
  protected handleMetadata = (): void => {
    // If player is not already open, we'll want to open it.
    if (!this.isOpen.value) {
      this.openPlayer();
    }

    // Set total duration of loaded media.
    this.totalPlayingTime.value = this.player.value?.duration || 0;

    // Set elapsed time of loaded media.
    this.elapsedPlayingTime.value = this.player.value?.currentTime || 0;

    // Set loaded media in cache.
    if (this.cacheEnabled && this.loadedMedia.value) {
      this.addToCache('podcast-player.state', {
        data: this.loadedMedia.value,
        timeElapsed: this.elapsedPlayingTime.value,
      });
    }

    // Emit event.
    this.events.emit('loaded', this, this.loadedMedia.value);
  };

  /**
   * Handle pausing of player.
   *
   * @returns { PodcastPlayer }
   */
  protected handlePausing = (): PodcastPlayer => {
    // Mark player as paused.
    this.isPlaying.value = false;

    // Emit event.
    this.events.emit('pause', this, this.loadedMedia.value);

    return this;
  };

  /**
   * Handle playing of player.
   *
   * @returns { PodcastPlayer }
   */
  protected handlePlaying = (): PodcastPlayer => {
    if (!this.isOpen.value) {
      this.openPlayer();
    }

    // Mark player as playing.
    this.isPlaying.value = true;

    // Emit event.
    this.events.emit('play', this, this.loadedMedia.value);

    return this;
  };

  /**
   * Handle ended playing of player.
   *
   * @returns { PodcastPlayer }
   */
  protected handleEnded = (): PodcastPlayer => {
     // If elapsed playing time is the same as total time,
    // we assume the loaded media has been fully played.
    if (this.elapsedPlayingTime.value === this.totalPlayingTime.value) {
      this.events.emit('finished', this, this.loadedMedia.value);
    }

    return this;
  };

  /**
   * Update playing process of loaded media.
   *
   * @returns { void }
   */
  protected updateProgress = (): void => {
    // Get current time of player.
    const playerElapsedTime = this.player.value?.currentTime || 0;

    // Set elapsed playing time of media.
    this.elapsedPlayingTime.value = playerElapsedTime;

    // Update cache with progress status.
    if (this.cacheEnabled && this.loadedMedia.value) {
      this.addToCache('podcast-player.state', {
        data: this.loadedMedia.value,
        timeElapsed: playerElapsedTime,
      });
    }

    // Emit event.
    this.events.emit('progress', this, playerElapsedTime, this.totalPlayingTime.value, this.loadedMedia.value);
  };

  /**
   * Set time of media in player.
   *
   * @param { number } time
   * @returns { PodcastPlayer | undefined }
   */
  public position = (time: number): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return;
    }

    // Set player position.
    this.player.value.currentTime = time;

    return this;
  };

  /**
   * Set time of media in player by percentage of total time.
   *
   * @param { number } percentage
   * @returns { PodcastPlayer | undefined }
   */
  public positionByPercentage = (percentage: number): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return;
    }

    // Set player position by percentage.
    this.player.value.currentTime = (this.player.value.duration * percentage) / 100;

    return this;
  };

  /**
   * Seek episode backwards.
   *
   * @param { number } seconds
   * @returns { PodcastPlayer | undefined }
   */
  public seekBackwards = (seconds: number): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return;
    }

    // Calculate time position of media after rewind.
    const time = this.player.value.currentTime - seconds;

    // Set (unsigned) time position of player.
    this.player.value.currentTime = time < 0 ? 0 : time;

    return this;
  };

  /**
   * Seek episode forwards.
   *
   * @param { number } seconds
   * @returns { PodcastPlayer | undefined }
   */
  public seekForwards = (seconds: number): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return;
    }

    // Duration of current media.
    const { currentTime, duration } = this.player.value;

    // Calculate time position of media after forward.
    const time = currentTime + seconds;

    // Set time position of player.
    this.player.value.currentTime = time >= duration ? duration : time;

    return this;
  };

  /**
   * Maximize player.
   *
   * @returns { PodcastPlayer }
   */
  public maximize = (): PodcastPlayer => {
    this.isMaximized.value = true;
    this.events.emit('maximize', this);
    return this;
  };

  /**
   * Minimize player.
   *
   * @returns { PodcastPlayer }
   */
  public minimize = (): PodcastPlayer => {
    this.isMaximized.value = false;
    this.events.emit('minimize', this);
    return this;
  };

  /**
   * Open player.
   *
   * @returns { void }
   */
  public openPlayer = (): void => {
    // Mark player as open.
    this.isOpen.value = true;

    // Emit event.
    this.events.emit('open', this);
  };

  /**
   * Close player.
   *
   * @returns { void }
   */
  public closePlayer = (): void => {
    // Mark player as closed.
    this.isOpen.value = false;

    // Emit event.
    this.events.emit('close', this);
  };

  /**
   * Close player.
   *
   * @returns { PodcastPlayer | undefined }
   */
  public resetPlayer = (): PodcastPlayer | undefined => {
    if (!this.player.value) {
      return;
    }

    // Pause player without triggering event.
    this.player.value.pause();

    // Remove currently loaded media from player.
    this.player.value.src = '';

    // Reset internal values.
    this.loadedMedia.value = undefined;
    this.totalPlayingTime.value = 0;
    this.elapsedPlayingTime.value = 0;
    this.isMaximized.value = false;

    // Remove from cache.
    if (this.cacheEnabled) {
      this.clearFromCache('podcast-player.state');
    }

    // Emit event.
    this.events.emit('reset', this);

    return this;
  };

  /**
   * Reset and close player.
   *
   * @returns { void }
   */
  public resetAndClosePlayer = (): void => {
    // Mark player as closed.
    this.isOpen.value = false;

    // Reset player after it's been closed.
    if (this.player.value) {
      this.resetPlayer();
    }

    // Emit event.
    this.events.emit('close', this);
  };

  /**
   * Get value from cache (local storage).
   *
   * @param { string } key
   * @returns { PodcastPlayerCacheValue | undefined }
   */
  public getFromCache = (key: string, defaultValue?: PodcastPlayerCacheValue): PodcastPlayerCacheValue | undefined => {
    if (!this.cacheEnabled) {
      return;
    }

    const value = localStorage.getItem(`${this.options.cacheKey}.${key}`);
    return value ? JSON.parse(value) as PodcastPlayerCacheValue : defaultValue;
  };

  /**
   * Add value to cache (local storage).
   *
   * @param { string } key
   * @param { PodcastPlayerCacheValue } value
   * @returns { this }
   */
  public addToCache = (key: string, value: PodcastPlayerCacheValue): this => {
    if (!this.cacheEnabled) {
      return this;
    }

    localStorage.setItem(`${this.options.cacheKey}.${key}`, JSON.stringify(value));
    return this;
  };

  /**
   * Clear value to cache (local storage).
   *
   * @param { string } key
   * @returns { this }
   */
  public clearFromCache = (key: string): this => {
    if (!this.cacheEnabled) {
      return this;
    }

    localStorage.removeItem(`${this.options.cacheKey}.${key}`);
    return this;
  };

  /**
   * Get cache key.
   */
  get cacheKey(): string | undefined {
    return this.options.cacheKey;
  }

  /**
   * Whether cache is enabled or not.
   */
  get cacheEnabled(): boolean {
    return this.options.cacheKey !== undefined;
  }

  /**
   * Get whether player is open or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get open(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isOpen.value);
  }

  /**
   * Get current loaded media.
   *
   * @returns { ComputedRef<PlayerMedia | undefined> }
   */
  get media(): ComputedRef<PlayerMedia | undefined> {
    return computed<PlayerMedia | undefined>((): PlayerMedia | undefined => this.loadedMedia.value);
  }

  /**
   * Get whether media is playable or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get playable(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isPlayable.value !== PodcastPlayerMediaState.NOT_PLAYABLE);
  }

  /**
   * Get whether player is currently playing or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get playing(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isPlayable.value !== PodcastPlayerMediaState.NOT_PLAYABLE && this.isPlaying.value);
  }

  /**
   * Get whether player is currently maximized or not.
   *
   * @returns { ComputedRef<boolean> }
   */
  get maximized(): ComputedRef<boolean> {
    return computed<boolean>((): boolean => this.isMaximized.value);
  }

  /**
   * Get duration of current loaded media.
   *
   * @returns { ComputedRef<number> }
   */
  get duration(): ComputedRef<number> {
    return computed<number>((): number => this.totalPlayingTime.value);
  }

  /**
   * Time played in seconds.
   *
   * @returns { ComputedRef<number> }
   */
  get timePlayedInSeconds(): ComputedRef<number> {
    return computed<number>((): number => this.elapsedPlayingTime.value);
  }

  /**
   * Time played in percentage.
   *
   * @returns { ComputedRef<number> }
   */
  get timePlayedInPercentage(): ComputedRef<number> {
    return computed<number>((): number => {
      if (this.elapsedPlayingTime.value === 0 || this.totalPlayingTime.value === 0) {
        return 0;
      }

      return (this.elapsedPlayingTime.value * 100) / this.totalPlayingTime.value || 0;
    });
  }

  /**
   * Remaining time in seconds.
   *
   * @returns { ComputedRef<number> }
   */
  get remainingTimeInSeconds(): ComputedRef<number> {
    return computed<number>((): number => (this.totalPlayingTime.value - this.elapsedPlayingTime.value));
  }

  /**
   * Remaining time in percentage.
   *
   * @returns { ComputedRef<number> }
   */
  get remainingTimeInPercentage(): ComputedRef<number> {
    return computed<number>((): number => (100 - this.timePlayedInPercentage.value));
  }
}

export type PodcastPlayerOptions = {
  cacheKey?: string;
};

export type PodcastPlayerCacheValue = PodcastPlayerState | boolean;

export type PodcastPlayerState = {
  data: PlayerMedia;
  timeElapsed: number;
};

export type PodcastPlayerEvent = 'load' | 'loaded' | 'cached' | 'play' | 'pause' | 'progress' | 'finished' | 'maximize' | 'minimize' | 'reset' | 'open' | 'close' | 'error';

export enum PodcastPlayerMediaState {
  NOT_PLAYABLE,
  PLAYABLE,
  FINISHABLE,
}
