import type { App } from 'vue';
import { livePlayerKey } from './composable';
import LivePlayer from './player';

/**
 * Install plugin with Vue.
 *
 * @param { App } app
 * @returns { void }
 */
export default function install(app: App): void {
  app.provide<LivePlayer>(livePlayerKey, new LivePlayer);
}
