import type { App } from 'vue';
import { podcastPlayerKey } from './composable';
import PodcastPlayer, { type PodcastPlayerOptions } from './player';

/**
 * Install plugin with Vue.
 *
 * @param { App } app
 * @param { PodcastPlayerOptions } options
 * @returns { void }
 */
export default function install(app: App, options: PodcastPlayerOptions = {}): void {
  app.provide<PodcastPlayer>(podcastPlayerKey, new PodcastPlayer(options));
}
