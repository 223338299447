<template>
  <component
    :is="modal.component"
    v-for="[id, modal] in modals.all.entries()"
    :key="id"
    v-bind="{ modalId: id, ...modal.props }"
    @close="modals.hide(id)"
  />
</template>

<script lang="ts" setup>
import { useModals } from '@shared/plugins/modals';

const modals = useModals();
</script>
