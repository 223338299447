import type { App } from 'vue';
import { modalsKey } from './composable';

import Modals from './modals';
export type { Modals };

import ContainerComponent from './components/Container.vue';
import ModalComponent from './components/Modal.vue';

export default function install(app: App) {
  app.provide<Modals>(modalsKey, new Modals);
  app.component('ModalsContainer', ContainerComponent);
  app.component('Modal', ModalComponent);
}
