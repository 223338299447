import type { App } from 'vue';
import { route as ZiggyRoute, type Config as ZiggyConfig } from 'ziggy-js';
import { ziggyKey } from './composable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function route(name?: any, params?: any, absolute?: boolean, config?: ZiggyConfig): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return ZiggyRoute(name, params, absolute, config);
}

export default function install(app: App, defaultConfig: ZiggyConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const r = (name: string, params?: any, absolute?: boolean, config?: ZiggyConfig) => route(name, params, absolute, config || defaultConfig);

  app.config.globalProperties.route = r;
  app.provide(ziggyKey, r);
}
